<template>
  <div class="manage">
    <div class="manage-header">
      <!-- 新增按钮 -->
      <el-button type="primary" @click="handleCreate">+ 新增</el-button>
      <!-- 对话框:点击新增或编辑才会弹出表单 -->
      <!-- :before-close="closeDialog" 点击关闭的x之前要做的事情 -->
      <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :before-close="closeDialog" :close-on-click-modal="false">
        <!-- 表单Form -->
        <!-- ref=form:为了通过this.$refs调用组件的方法 -->
        <el-form :inline="true" :model="form" :rules="rules" ref="form" label-width="80px">
          <!-- 每一项表单域:el-form-item -->
          <el-form-item label="租户名称" prop="tenantName">
            <el-input placeholder="请输入租户名称" v-model="form.tenantName"></el-input>
          </el-form-item>
          <el-form-item label="公司名称" prop="shopAddress">
            <el-input placeholder="请输入公司名称" v-model="form.companyName"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="bossName">
            <el-input placeholder="请输入联系人" v-model="form.linkman"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="photo">
            <el-input placeholder="请输入手机号" v-model="form.photo"></el-input>
          </el-form-item>
          <el-form-item label="到期时间" prop="expTime">
            <el-date-picker
                v-model="form.expTime"
                type="date"
                value-format="yyyy-MM-dd 23:59:59"
                placeholder="选择到期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" placeholder="请输入联系人" v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 搜索框 -->
      <el-form :inline="true">
        <el-form-item>
          <el-input v-model="searchForm.keyWords" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <!-- 商铺Table -->
      <el-table :data="tableData" border style="width: 100%" height="90%">
        <el-table-column width="50" prop="id" label="id" fixed></el-table-column>
        <el-table-column prop="tenantName" label="租户名称" fixed></el-table-column>
        <el-table-column prop="companyName" label="公司名称"></el-table-column>
        <el-table-column prop="linkman" label="联系人"></el-table-column>
        <el-table-column prop="photo" label="电话"></el-table-column>
        <el-table-column prop="expTime" label="到期时间"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <!-- 自定义列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pager">
        <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request'

export default {
  data() {
    return {
      // 表单绑定的数据
      form: {
        tenantName: '',
        companyName: '',
        expTime: '',
        linkman: '',
        photo: '',
        remark: '',
      },
      // 表单验证规则
      rules: {
        tenantName: [{required: true, message: '请输入租户名称', trigger: 'blur'}],
        companyName: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
        linkman: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        photo: [{required: true, message: '请输入联系电话',pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, trigger: 'blur'}],
      },
      // 表单是否打开
      dialogVisible: false,
      // 列表数据
      tableData: [],
      // 打开表单:新建0,编辑1
      modalType: 0,
      // 分页的对象
      pageData: {
        page: 1,
        limit: 20
      },
      // 分页页数
      total: 0,
      // 搜索框表单
      searchForm: {
        keyWords: '',
      },
    }
  },
  created() {

  },
  methods: {
    // 获取列表数据
    getList() {
      http.post('/pmt/sysTenant/admin/getTenantPageList', this.searchForm)
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.pageData.page = tData.pageNum
              this.pageData.limit = tData.pageSize
              this.total = tData.total
              this.tableData = tData.list
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 表单提交
    submit() {
      // 要用箭头函数,若用function会报错,不知道为什么
      this.$refs.form.validate((valid) => {
        // 符合校验
        if (valid) {
          // 编辑
          http.post('/pmt/sysTenant/admin/save', this.form).then(()=>{
            this.getList()
          })
          // 清空,关闭
          this.closeDialog()
        }
      })
    },
    // 关闭对话框
    closeDialog() {
      // 先重置
      this.form.expTime=''
      this.form.tenantName=''
      this.form.companyName=''
      this.form.linkman=''
      this.form.photo=''
      this.form.remark=''
      // 后关闭
      this.dialogVisible = false
    },
    // 编辑按钮
    handleEdit(index) {
      this.modalType = 1
      this.openForm()
      // 深拷贝
      this.form = JSON.parse(JSON.stringify(index))
    },
    // 删除按钮
    handleDelete(index) {
      this.$confirm('此操作将删除该租户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作:根据后端接口,参数是对象,id是唯一标识符
        http.post('/pmt/sysTenant/admin/delete?id='+index.id).then(res => {
          this.$message({
            type: res && res.data.code === 666 ?  'success' : 'error',
            message: res.data.responseMsg
          })
          this.getList()
        });
      }).catch(() => {
        // 点击取消:不删除了
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新建按钮
    handleCreate() {
      this.modalType = 0
      this.openForm()
    },
    // 打开表单
    openForm() {
      this.dialogVisible = true
    },
    // 改变页码
    currentChange(val) {
      this.pageData.page = val
      this.getList()
    },
    // 搜索
    search() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.manage {
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .common-table {
    height: 90%;
    position: relative;

    .pager {
      position: absolute;
      right:20px;
      bottom: 0;
    }
  }
}
</style>
<template>
  <div class="manage">
    <el-dialog title="结算" :visible.sync="doSettleAccountsVisible" width="30%">
      <el-form>
        <el-form-item label="选择结算截止时间" label-width="300" :before-close="doSettleAccountsCloseDialog">
          <el-date-picker type="datetime" v-model="doSettleAccountsDate" value-format="yyyy-MM-dd 00:00:00" default-time="23:59:59" placeholder="结束时间" :picker-options="pickerOptions"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doSettleAccountsSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <div class="manage-header">
      <!-- 对话框:点击新增或编辑才会弹出表单 -->
      <!-- :before-close="closeDialog" 点击关闭的x之前要做的事情 -->
      <el-dialog title="详情" :visible.sync="dialogVisible" width="60%" :before-close="closeDialog">
        <!-- 表单Form -->
        <!-- ref=form:为了通过this.$refs调用组件的方法 -->
        <el-table :data="applicationFormTableData" stripe style="width: 100%" height="90%">
          <el-table-column prop="id" label="id" width="50"></el-table-column>
          <el-table-column prop="taskName" label="任务名称" width="100"></el-table-column>
          <el-table-column prop="createTime" label="提交时间"></el-table-column>
          <el-table-column prop="userName" label="促销员"></el-table-column>
          <el-table-column prop="auditFormNumber" label="审核单数"></el-table-column>
          <el-table-column prop="amount" label="实际金额"></el-table-column>
          <el-table-column prop="formStatusStr" label="状态" width="100"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeDialog">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 搜索框 -->
      <el-form :inline="true">
        <el-date-picker type="datetime" v-model="searchForm.startTime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="开始时间"/>
        <el-date-picker type="datetime" v-model="searchForm.endTime" value-format="yyyy-MM-dd HH:mm:ss"
                        default-time="23:59:59" placeholder="结束时间"/>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="exportExcel">导出Excel</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSettleAccounts">结算</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <!-- 用户数据Table -->
      <el-table :data="tableData" stripe style="width: 100%" height="90%">
        <el-table-column prop="id" label="id" width="50"></el-table-column>
        <el-table-column prop="settleAccountsTime" label="结算时间"></el-table-column>
        <el-table-column prop="taskInfoName" label="任务名称"></el-table-column>
        <el-table-column prop="userName" label="促销员"></el-table-column>
        <el-table-column prop="shopName" label="商铺名称"></el-table-column>
        <el-table-column prop="formNumber" label="实际单数"></el-table-column>
        <el-table-column prop="actualAmount" label="实际金额"></el-table-column>
        <el-table-column prop="totalQuantity" label="剩余库存"></el-table-column>
        <!-- 自定义列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="getApplicationFormBySettleAccounts(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pager">
        <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request'

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        }
      },
      // 表单验证规则
      rules: {
        realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
        wxNickname: [{required: true, message: '请输入微信姓名', trigger: 'blur'}],
        phone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
        shopInfoId: [{required: true, message: '请选择商铺', trigger: 'blur'}],
        userType: [{required: true, message: '请选择类别', trigger: 'blur'}],
        expirationTime: [{required: true, message: '请选择到期时间', trigger: 'blur'}],
      },
      // 表单是否打开
      dialogVisible: false,
      // 列表数据
      tableData: [],
      // 分页的对象
      pageData: {
        page: 1,
        limit: 20
      },
      // 分页页数
      total: 0,
      // 搜索框表单
      searchForm: {
        startTime: '',
        endTime: ''
      },
      //支付编号显示
      addPayNumberVisible: false,
      //支付编号
      payNumberForm:{
        payNumber:'',
        settleAccountsId:'',
      },
      //执行结算
      doSettleAccountsVisible: false,
      doSettleAccountsDate:'',
      //对应的提交单
      applicationFormTableData: [],
      userTypeSelectList: [],
    }
  },
  created() {
    //加载页面初始信息
    this.getBaseData()
  },
  methods: {
    //获取页面初始化信息
    getBaseData() {
      http.get('/pmt/sysUser/admin/getBasicData')
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.shopSelectList = tData.shopSelectList
              this.userTypeSelectList = tData.userTypeSelectList
            } else {
              this.$message(res.data.responseMsg);
            }
          })

    },
    //结算
    doSettleAccountsCloseDialog(){
      this.doSettleAccountsVisible = false
      this.doSettleAccountsDate = ''
    },
    //点击结算
    doSettleAccounts(){
      this.doSettleAccountsVisible = true
    },
    //执行结算
    doSettleAccountsSubmit(){

      http.post('/pmt/settleAccounts/admin/doSettleAccounts' ,{"endTime": this.doSettleAccountsDate})
          .then(res => {
            if (res.data.code === 666) {
              this.getList()
              this.doSettleAccountsCloseDialog()
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 查看提交单信息
    getApplicationFormBySettleAccounts(row) {
      http.get('/pmt/applicationForm/admin/getListBySettleAccountsId?settleAccountsId=' + row.id)
          .then(res => {
            if (res.data.code === 666) {
              this.applicationFormTableData = res.data.tData
              this.openForm()
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 获取列表数据
    getList() {
      http.post('/pmt/settleAccounts/admin/getSettleAccountsPageList', this.searchForm)
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.pageData.page = tData.pageNum
              this.pageData.limit = tData.pageSize
              this.total = tData.total
              this.tableData = tData.list
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 关闭对话框
    closeDialog() {
      // 先重置
      this.applicationFormTableData = []
      // 后关闭
      this.dialogVisible = false
    },
    // 打开表单
    openForm() {
      this.dialogVisible = true
    },
    // 改变页码
    currentChange(val) {
      this.pageData.page = val
      this.getList()
    },
    // 搜索
    search() {
      this.getList()
    },
    //导出excel
    exportExcel() {
      http.post('/pmt/settleAccounts/admin/expSettleAccountsWithExcel', this.searchForm)
          .then(res => {
            if (res.data.code === 666) {
              const urlPath = res.data.tData
              window.open(urlPath, '_blank');
            } else {
              this.$message(res.data.responseMsg);
            }
          })


    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.manage {
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .common-table {
    height: 90%;
    position: relative;

    .pager {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
<template>
  <div class="manage">
    <!-- 对话框:点击新增或编辑才会弹出表单 -->
    <!-- :before-close="closeDialog" 点击关闭的x之前要做的事情 -->
    <el-dialog :title="modalType === 0 ? '添加用户' : '修改用户'" :visible.sync="dialogVisible" width="50%" :before-close="closeDialog" :close-on-click-modal="false">
      <div v-if="modalType === 0" style="height: 80px;">
        <el-input placeholder="请输入用户注册码" v-model="queryRegisterCode" style="width: 200px;"></el-input>
        <el-button type="primary" @click="queryByCode" style="margin-left: 10px;">查询用户</el-button>
      </div>
      <!-- 表单Form -->
      <!-- ref=form:为了通过this.$refs调用组件的方法 -->
      <el-form :inline="true" :model="form" :rules="rules" ref="form" label-width="80px">
        <!-- 每一项表单域:el-form-item -->
        <el-form-item label="真实姓名" placeholder="请输入真实姓名" prop="realName">
          <el-input v-model="form.realName"></el-input>
        </el-form-item>
        <el-form-item label="微信名称" placeholder="请输入微信名称" prop="wxNickname">
          <el-input v-model="form.wxNickname"></el-input>
        </el-form-item>
        <el-form-item label="手机号" placeholder="请输入手机号" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="所属商铺" prop="shopInfoId">
          <el-select v-model="form.shopInfoId" placeholder="请选择商铺" filterable>
            <el-option
                v-for="item in shopSelectList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到期时间" prop="expirationTime">
          <el-date-picker
              v-model="form.expirationTime"
              type="datetime"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择到期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <div class="manage-header">
      <!-- 搜索框 -->
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handlecreate">添加用户</el-button>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <!-- 用户数据Table -->
      <el-table :data="tableData" stripe style="width: 100%" height="90%">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="realName" label="真实名称"></el-table-column>
        <el-table-column prop="wxNickname" label="微信名称"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="shopInfoName" label="所属商铺"></el-table-column>
        <el-table-column prop="expirationTime" label="到期时间"></el-table-column>
        <!-- 自定义列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pager">
        <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request'

export default {
  data() {
    return {
      // 表单绑定的数据
      form: {},
      // 表单验证规则
      rules: {
        realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
        wxNickname: [{required: true, message: '请输入微信姓名', trigger: 'blur'}],
        phone: [{required: true, message: '请输入手机号', pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, trigger: 'blur'}],
        shopInfoId: [{required: true, message: '请选择商铺', trigger: 'blur'}],
      },
      // 表单是否打开
      dialogVisible: false,
      // 列表数据
      tableData: [],
      // 打开表单:新建0,编辑1
      modalType: 0,
      // 分页的对象
      pageData: {
        page: 1,
        limit: 20
      },
      // 分页页数
      total: 0,
      // 搜索框表单
      searchForm: {
        name: ''
      },
      //商铺下拉
      shopSelectList: [],
      //用户注册码搜索
      queryRegisterCode: '',
    }
  },
  created() {
    //加载页面初始信息
    this.getBaseData()
  },
  methods: {
    //通过注册码查询用户
    queryByCode() {
      if (this.queryRegisterCode.length === 0) {
        this.$message("请输入注册码")
        return
      }
      console.log('/pmt/sysUser/admin/getUserByRegisterCode?registerCode=' + this.queryRegisterCode)
      http.get('/pmt/sysUser/admin/getUserByRegisterCode?registerCode=' + this.queryRegisterCode)
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.form = tData
            } else {
              this.$message(res.data.responseMsg);
            }
          })


      console.log(this.queryRegisterCode)
    },

    //获取页面初始化信息
    getBaseData() {
      http.get('/pmt/sysUser/admin/getBasicData')
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.shopSelectList = tData.shopSelectList
            } else {
              this.$message(res.data.responseMsg);
            }
          })

    },

    // 获取列表数据
    getList() {
      http.post('/pmt/sysUser/admin/getUserPageList', {})
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.pageData.page = tData.pageNum
              this.pageData.limit = tData.pageSize
              this.total = tData.total
              this.tableData = tData.list
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 表单提交
    submit() {
      this.form.modalType = this.modalType
      // 要用箭头函数,若用function会报错,不知道为什么
      this.$refs.form.validate((valid) => {
        // 符合校验
        if (valid) {
          // 编辑
          http.post('/pmt/sysUser/admin/save', this.form).then(res => {
            this.$message({
              type: res && res.data.code === 666 ? 'success' : 'error',
              message: res.data.responseMsg
            })
            this.getList()
          })
          // 清空,关闭
          this.closeDialog()
        }
      })
    },
    // 关闭对话框
    closeDialog() {
      // 先重置
      this.$refs.form.resetFields()
      // 后关闭
      this.dialogVisible = false
    },
    // 编辑按钮
    handleEdit(index) {
      this.modalType = 1
      this.openForm()
      // 深拷贝
      this.form = JSON.parse(JSON.stringify(index))
    },
    // 删除按钮
    handleDelete(index) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作:根据后端接口,参数是对象,id是唯一标识符
        this.deleteUser({id: index.id}).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList()
        });
      }).catch(() => {
        // 点击取消:不删除了
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //删除用户
    deleteUser(idObj) {
      http.post('/pmt/sysUser/admin/deleteById', idObj).then(res => {
        this.$message({
          type: res && res.data.code === 666 ? 'success' : 'error',
          message: res.data.responseMsg
        })
        this.getList()
      })
    },
    // 新建按钮
    handlecreate() {
      this.modalType = 0
      this.form = {}
      this.openForm()
    },
    // 打开表单
    openForm() {
      this.dialogVisible = true
    },
    // 改变页码
    currentChange(val) {
      this.pageData.page = val
      this.getList()
    },
    // 搜索
    search() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.manage {
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: normal;
  }

  .common-table {
    height: 90%;
    position: relative;

    .pager {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
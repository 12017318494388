<template>
  <el-row>
    <el-col :span="8">
      <!-- user卡片 -->
      <el-card>
        <div class="user">
          <img src="../assets/images/user.png" alt="">
          <div class="userInfo">
            <p div class="name">王二狗</p>
            <p div class="access">董事长</p>
            <p div class="access">优润商贸有限公司</p>
          </div>
        </div>
      </el-card>
      <!-- table卡片 -->
      <el-card :body-style="{ flexWrap: 'wrap', padding: 0,}" style="margin-top: 20px; height: 155px;">
        <div class="messageDiv" style="color: #2ec7c9;">您有100张单据需要审核</div>
        <div class="messageDiv" style="color: #ffb980;">您已经有3天没有进行结算了</div>
        <div class="messageDiv" style="color: #999999;">测试测试</div>
      </el-card>

      <el-card :body-style="{ flexWrap: 'wrap', padding: 0, }" style="margin-top: 20px; height: 155px;">
        <div class="messageDiv" style="color: #2ec7c9;">本月内有8家店还未参与任何活动</div>
        <div class="messageDiv" style="color: #ffb980;">东门烟酒小店上月销量下滑80%</div>
        <div class="messageDiv" style="color: #999999;">您有6家店库存不足，请注意!!!</div>
      </el-card>
    </el-col>
    <el-col :span="16">
      <div class="num">
        <el-card :body-style="{ display: 'flex', padding: 0 }">
          <i class="icon" :class="`el-icon-success`" style=" background-color: #2ec7c9 "></i>
          <div class="details">
            <p class="price">999</p>
            <p class="desc">今日完成单数</p>
          </div>
        </el-card>
        <el-card :body-style="{ display: 'flex', padding: 0 }">
          <i class="icon" :class="`el-icon-s-goods`" style=" backgroundColor: #5ab1ef "></i>
          <div class="details">
            <p class="price">{{ priceFormate(999.99) }}</p>
            <p class="desc">今日预估金额</p>
          </div>
        </el-card>
        <el-card :body-style="{ display: 'flex', padding: 0 }">
          <i class="icon" :class="`el-icon-s-star-on`" style=" backgroundColor: #ffb980 "></i>
          <div class="details">
            <p class="price">{{ priceFormate(999.99) }}</p>
            <p class="desc">今日已审核金额</p>
          </div>
        </el-card>
      </div>
      <!-- echarts图表 -->
      <div style="margin-left:20px">
        <!-- 7天任务完成情况折线图 -->
        <el-card style="height:250px" :body-style="{padding: 0}">
          <div ref="taskEcharts" style="height:250px"></div>
        </el-card>
        <div class="graph">
          <!-- 本日任务完成最多的门店 -->
          <el-card style="height:168px" :body-style="{padding: 0}">
            <div ref="maxShopEcharts" style="height:168px"></div>
          </el-card>
          <!-- 最近7天完成最多的任务单数 -->
          <el-card style="height:168px" :body-style="{padding: 0}">
            <div ref="minShopEcharts" style="height:168px"></div>
          </el-card>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
//报表
import * as echarts from 'echarts'

export default {
  data() {
    return {
      TableData: [],
      items: [
        {type: '', label: '您有提交单据需要审核'},
        {type: 'success', label: '您已经有3天没有结算了'},
        {type: 'info', label: '您吃饭了吗'},
        {type: 'danger', label: '标签四'},
        {type: 'warning', label: '标签五'}
      ]
    }
  },
  methods: {
    priceFormate(price) {
      return "￥" + price
    }
  },
  mounted() {
    //本周最多的客户统计
    const minShopEcharts = echarts.init(this.$refs.minShopEcharts)
    // 指定图表的配置项和数据
    var minShopOption = {
      title: {
        left: 'center',
        text: '最近7天完成最少的商铺',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: 30,
        containLabel: true
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        data: ['东门王家小卖部', '西门吹雪烟酒超市', 'USA', 'India', 'China', 'World'],
      },
      series: [
        {
          type: 'bar',
          data: [9, 8, 5, 2, 1, 0],
        }
      ]
    };
    minShopEcharts && minShopEcharts.setOption(minShopOption);

    //本周最多的客户统计
    const maxShopEcharts = echarts.init(this.$refs.maxShopEcharts)
    // 指定图表的配置项和数据
    var maxShopOption = {
      title: {
        left: 'center',
        text: '最近7天完成最多的商铺',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: 30,
        containLabel: true
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        data: ['东门王家小卖部', '西门吹雪烟酒超市', 'USA', 'India', 'China', 'World'],
      },
      series: [
        {
          type: 'bar',
          data: [100, 200, 300, 330, 440, 500],
        }
      ]
    };
    maxShopEcharts && maxShopEcharts.setOption(maxShopOption);

    //任务图表
    const taskEcharts = echarts.init(this.$refs.taskEcharts)
    var taskOption = {
      title: {
        text: '最近7天任务情况',
        left: 'center',
        top: 0
      },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
      },
      legend: {
        // 设置滚动效果
        type: 'scroll',
        // 设置每个图例的宽度
        itemWidth: 20,
        bottom: 0,
        data: ['瓜子促销活动', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine', '111', '222', '333', '444', '555', '666'],
        top: 20
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '瓜子促销活动',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '111',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '222',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '333',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '444',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '555',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '666',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: 'Union Ads',
          type: 'line',
          stack: 'Total',
          data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
          name: 'Video Ads',
          type: 'line',
          stack: 'Total',
          data: [150, 232, 201, 154, 190, 330, 410]
        },
        {
          name: 'Direct',
          type: 'line',
          stack: 'Total',
          data: [320, 332, 301, 334, 390, 330, 320]
        },
        {
          name: 'Search Engine',
          type: 'line',
          stack: 'Total',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        }
      ]
    };
    taskOption && taskEcharts.setOption(taskOption);


  }
}
</script>

<style lang="less" scoped>
.user {
  // 垂直居中
  display: flex;
  align-items: center;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 40px;
  }

  .userInfo {
    .name {
      font-size: 32px;
      margin-bottom: 10px;
    }

    .access {
      line-height: 30px;
      color: #999999;
    }
  }
}

.num {
  display: flex;
  // 要换行
  flex-wrap: wrap;
  // 从头到尾均匀排列
  justify-content: space-between;
  margin-left: 20px;

  .el-card {
    width: 32%;
    margin-bottom: 20px;

    .icon {
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 30px;
      color: #fff;
    }

    .details {
      // 竖着排且居中
      display: flex;
      flex-direction: column;
      justify-content: center;

      margin-left: 15px;

      .price {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 30px;
      }

      .desc {
        font-size: 14px;
        color: #999;
        text-align: center;
      }
    }
  }
}

.graph {
  display: flex;
  // 两个靠边
  justify-content: space-between;
  margin-top: 20px;

  .el-card {
    width: 49%;
  }
}

.messageDiv {
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
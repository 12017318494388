<template>
  <div class="manage">
    <div class="manage-header">
      <!-- 新增按钮 -->
      <el-button type="primary" @click="handleCreate">+ 新增</el-button>
      <!-- 对话框:点击新增或编辑才会弹出表单 -->
      <!-- :before-close="closeDialog" 点击关闭的x之前要做的事情 -->
      <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :before-close="closeDialog" :close-on-click-modal="false">
        <!-- 表单Form -->
        <!-- ref=form:为了通过this.$refs调用组件的方法 -->
        <el-form :inline="true" :model="form" :rules="rules" ref="form" label-width="80px" class="form-container">
          <!-- 每一项表单域:el-form-item -->
          <el-form-item label="选择商品" prop="unit" class="item00">
            <el-select v-model="form.goodsId" filterable placeholder="请选择状态" @change="changeGoods">
              <el-option
                  v-for="item in goodsList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务名称" prop="taskName" class="item00">
            <el-input v-model="form.taskName" readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="图标" prop="taskIconImgUrl" class="item00">
            <img :src="form.taskIconImgUrl ? form.taskIconImgUrl : 'https://www.xjy.asia/uploadfile/sys/notimg.jpg'"
                 class="avatar">
          </el-form-item>
          <el-form-item label="任务单位" prop="unit" class="item00">
            <el-input v-model="form.unitStr" readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务单价" prop="unitPrice" class="item00">
            <el-input-number
                v-model="form.unitPrice"
                :precision="1"
                :min="0.1"
                :step="0.1"
                :max="100.0"
                :controls="false"
            />
          </el-form-item>
          <el-form-item label="任务状态" prop="taskStatus" class="item00">
            <el-col :span="12">
              <el-select v-model="form.taskStatus" placeholder="请选择状态">
                <el-option
                    v-for="item in taskStatus"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="开始时间" prop="startTime">
            <el-date-picker
                v-model="form.startTime"
                type="datetime"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择任务开始时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="endTime">
            <el-date-picker
                v-model="form.endTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                default-time="23:59:59"
                placeholder="选择任务结束时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="任务详情" prop="taskContent" class="item01">
            <el-input type="textarea" :autosize="{minRows:6,maxRows:50}" placeholder="请输入详细信息"
                      v-model="form.taskContent" style="width: 600px;"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 搜索框 -->
      <el-form :inline="true">
        <el-form-item>
          <el-input v-model="searchForm.keyWords" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <!-- Table -->
      <el-table :data="tableData" border style="width: 100%" height="90%">
        <el-table-column width="50" prop="id" label="id" fixed></el-table-column>
        <el-table-column prop="taskName" label="任务名称" fixed></el-table-column>
        <el-table-column width="60" prop="unitPrice" label="单价" fixed></el-table-column>
        <el-table-column width="60" prop="unitStr" label="单位" fixed></el-table-column>
        <el-table-column prop="taskStatusStr" label="状态" width="50"></el-table-column>
        <el-table-column prop="taskIconImgUrl" label="图标">
          <template v-slot="scope">
            <el-image :src="scope.row.taskIconImgUrl" class="avatar"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间"></el-table-column>
        <el-table-column prop="endTime" label="结束时间"></el-table-column>
        <el-table-column prop="createUserStr" label="创建人" width="70"></el-table-column>
        <el-table-column prop="updateUserStr" label="修改人" width="70"></el-table-column>
        <!-- 自定义列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pager">
        <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request'

export default {
  data() {
    return {
      // 表单绑定的数据
      form: {},
      // 表单验证规则
      rules: {
        goodsId: [{required: true, message: '请选择商品', trigger: 'blur'}],
        taskContent: [{required: true, message: '请输入详情', trigger: 'blur'}],
        startTime: [{required: true, message: '请选择开始时间', trigger: 'blur'}],
        endTime: [{required: true, message: '请选择结束时间', trigger: 'blur'}],
        taskStatus: [{required: true, message: '请选择任务状态', trigger: 'blur'}],
        unitPrice: [{required: true, message: '请输入单价', trigger: 'blur'}],
      },
      // 表单是否打开
      dialogVisible: false,
      // 列表数据
      tableData: [],
      // 打开表单:新建0,编辑1
      modalType: 0,
      // 分页的对象
      pageData: {
        page: 1,
        limit: 20
      },
      // 分页页数
      total: 0,
      // 搜索框表单
      searchForm: {
        keyWords: ''
      },
      //状态下拉
      taskStatus: [],
      //商品
      goodsList: [],
    }
  },
  created() {
    //加载页面初始信息
    this.getBaseData()
  },
  methods: {
    changeGoods(val) {
      const obj = this.goodsList.find(function (i) {
        return i.id === val
      });
      this.form.taskName = obj.goodsName
      this.form.unitStr = obj.unitStr
      this.form.taskIconImgUrl = obj.goodsImgUrl
    },
    //获取页面初始化信息
    getBaseData() {
      http.get('/pmt/taskInfo/admin/getBasicData')
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.taskStatus = tData.taskStatus
              this.goodsList = tData.goodsList;
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },

    // 获取列表数据
    getList() {
      http.post('/pmt/taskInfo/admin/getTaskPageList', this.searchForm)
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.pageData.page = tData.pageNum
              this.pageData.limit = tData.pageSize
              this.total = tData.total
              this.tableData = tData.list
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 表单提交
    submit() {
      // 要用箭头函数,若用function会报错,不知道为什么
      this.$refs.form.validate((valid) => {
        // 符合校验
        if (valid) {
          // 编辑
          http.post('/pmt/taskInfo/admin/save', this.form).then(() => {
            this.getList()
          })
          // 清空,关闭
          this.closeDialog()
        }
      })
    },
    // 关闭对话框
    closeDialog() {
      // 先重置
      this.form.id = ''
      this.form.taskContent = ''
      this.form.startTime = ''
      this.form.endTime = ''
      this.form.taskStatus = 0
      this.form.unitPrice = 0
      // 后关闭
      this.dialogVisible = false
    },
    // 编辑按钮
    handleEdit(index) {
      this.modalType = 1
      this.openForm()
      // 深拷贝
      this.form = JSON.parse(JSON.stringify(index))
    },
    // 删除按钮
    handleDelete(index) {
      this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作:根据后端接口,参数是对象,id是唯一标识符
        http.post('/pmt/taskInfo/admin/delete?id=' + index.id).then(res => {
          this.$message({
            type: res && res.data.code === 666 ? 'success' : 'error',
            message: res.data.responseMsg
          })
          this.getList()
        });
      }).catch(() => {
        // 点击取消:不删除了
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新建按钮
    handleCreate() {
      this.modalType = 0
      this.openForm()
      this.form = {}
    },
    // 打开表单
    openForm() {
      this.dialogVisible = true
    },
    // 改变页码
    currentChange(val) {
      this.pageData.page = val
      this.getList()
    },
    // 搜索
    search() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.manage {
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .common-table {
    height: 90%;
    position: relative;

    .pager {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }
}

//上传组件的样式
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.avatar2 {
  width: 300px;
  height: 120px;
  display: block;
}

//表单的样式
.form-container {
  display: flex;
  flex-wrap: wrap;
}

.form-container .item00 {
  flex: 0 0 80%; /* 每个表单项占用50%的宽度 */
  padding-right: 20px; /* 表单项之间的间隔 */
}
</style>
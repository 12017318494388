import Vue from "vue";
import VueRouter from "vue-router";
import Main from '../Views/Main'
import Home from '../Views/Home.vue'
import User from '../Views/User.vue'
import ApplicationForm from '../Views/ApplicationForm.vue'
import SettleAccounts from '../Views/SettleAccounts.vue'
import ShopInfo from '../Views/ShopInfo.vue'
import Login from '../Views/Login.vue'
import AdminUser from "@/Views/AdminUser";
import TaskInfo from "@/Views/TaskInfo";
import SysTenant from "@/Views/SysTenant";
import Goods from "@/Views/Goods";

Vue.use(VueRouter)

const routes = [
    // 主路由
    {
        path: '/',
        name:'Main',
        component: Main,
        redirect: '/home', // 重定向
        children: [
            //子路由
            { path: '/home', name: 'home', component: Home }, // 首页
            { path: '/user', name: 'user', component: User }, // 用户管理
            { path: '/ApplicationForm', name: 'ApplicationForm', component: ApplicationForm }, // 完成记录
            { path: '/SettleAccounts', name: 'SettleAccounts', component: SettleAccounts }, // 结账管理
            { path: '/shopInfo', name: 'shopInfo', component: ShopInfo }, // 商铺
            { path: '/adminUser', name: 'adminUser', component: AdminUser }, // 管理员
            { path: '/taskInfo', name: 'taskInfo', component: TaskInfo }, // 任务
            { path: '/goods', name: 'goods', component: Goods }, // 商品
            { path: '/sysTenant', name: 'sysTenant', component: SysTenant }, // 租户
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    }
]

const router = new VueRouter({
    routes
})

//白名单
const whiteList = ['/404', '/500'];
// 路由守卫:全局前置导航守卫
router.beforeEach((to, from, next) => {
    // 获取token
    const isLogin = sessionStorage.getItem('isLogin')
    if(whiteList.indexOf(to.path) !== -1){
        next()
    } else if (!isLogin && to.name !== 'login') {
        next({ name: 'login' })
    } else if (isLogin && to.name === 'login') {
        next({ name: 'home' })
    } else {
        next()
    }
})


export default router
<template>
  <div class="manage">
    <div class="manage-header">
      <!-- 新增按钮 -->
      <el-button type="primary" @click="handleCreate">+ 新增</el-button>
      <!-- 对话框:点击新增或编辑才会弹出表单 -->
      <!-- :before-close="closeDialog" 点击关闭的x之前要做的事情 -->
      <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :before-close="closeDialog" :close-on-click-modal="false">
        <!-- 表单Form -->
        <!-- ref=form:为了通过this.$refs调用组件的方法 -->
        <el-form :inline="true" :model="form" :rules="rules" ref="form" label-width="80px">
          <!-- 每一项表单域:el-form-item -->
          <el-form-item label="登录名" prop="userName">
            <el-input placeholder="请输入名称" v-model="form.userName" maxlength="20" minlength="4"></el-input>
          </el-form-item>
          <el-form-item label="真实名字" prop="realName">
            <el-input placeholder="请输入地址" v-model="form.realName"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input placeholder="请输入手机号" v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="用户类型" prop="userType">
            <el-col :span="12">
            <el-select v-model="form.userType" placeholder="请选择类型">
              <el-option
                  v-for="item in userTypeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
            </el-col>
          </el-form-item>
          <el-form-item v-if="userinfo.id === 1" label="租户" prop="userType">
            <el-col :span="12">
              <el-select v-model="form.tenantId" placeholder="请选择租户">
                <el-option
                    v-for="item in tenantList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" placeholder="请输入联系人" v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 搜索框 -->
      <el-form :inline="true">
        <el-form-item>
          <el-input v-model="searchForm.keyWords" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <!-- 商铺Table -->
      <el-table :data="tableData" border style="width: 100%" height="90%">
        <el-table-column width="50" prop="id" label="id" fixed></el-table-column>
        <el-table-column prop="userName" label="登录名" fixed></el-table-column>
        <el-table-column prop="realName" label="真实姓名"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="userTypeStr" label="类型"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <!-- 自定义列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
            <el-button type="text" @click="handleResetPwd(scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pager">
        <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request'

export default {
  data() {
    return {
      //登录的用户信息
      userinfo:{},
      // 表单绑定的数据
      form: {
        userName: '',
        realName: '',
        phone: '',
        userType: '',
        remark: '',
        tenantId: '',
      },
      // 表单验证规则
      rules: {
        userName: [{required: true, message: '只能是4-20位包含大小写字母数字和_', pattern: /^[A-Za-z0-9_]{4,20}$/, trigger: 'blur'}],
        realName: [{required: true, message: '请输入真实名称', trigger: 'blur'}],
        phone: [{required: true, message: '请输入电话',pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, trigger: 'blur'}],
        userType: [{required: true, message: '请选择类别', trigger: 'blur'}],
      },
      // 表单是否打开
      dialogVisible: false,
      // 列表数据
      tableData: [],
      // 打开表单:新建0,编辑1
      modalType: 0,
      // 分页的对象
      pageData: {
        page: 1,
        limit: 20
      },
      // 分页页数
      total: 0,
      // 搜索框表单
      searchForm: {
        keyWords: ''
      },
      //用户类型下拉
      userTypeList: [],
      //租户下拉
      tenantList:[],
    }
  },
  created() {
    this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
    //加载页面初始信息
    this.getBaseData()
  },
  methods: {
    //获取页面初始化信息
    getBaseData() {
      http.get('/pmt/sysAdminUser/admin/getBasicData')
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.userTypeList = tData.userTypeList
              this.tenantList = tData.tenantList
            } else {
              this.$message(res.data.responseMsg);
            }
          })

    },

    // 获取列表数据
    getList() {
      http.post('/pmt/sysAdminUser/admin/getUserPageList', this.searchForm)
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.pageData.page = tData.pageNum
              this.pageData.limit = tData.pageSize
              this.total = tData.total
              this.tableData = tData.list
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 表单提交
    submit() {
      // 要用箭头函数,若用function会报错,不知道为什么
      this.$refs.form.validate((valid) => {
        // 符合校验
        if (valid) {
          // 编辑
          http.post('/pmt/sysAdminUser/admin/save', this.form).then(()=>{
            this.getList()
          })
          // 清空,关闭
          this.closeDialog()
        }
      })
    },
    // 关闭对话框
    closeDialog() {
      // 先重置
      this.$refs.form.resetFields()
      // 后关闭
      this.dialogVisible = false
    },
    // 编辑按钮
    handleEdit(index) {
      this.modalType = 1
      this.openForm()
      // 深拷贝
      this.form = JSON.parse(JSON.stringify(index))
    },
    // 删除按钮
    handleDelete(index) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作:根据后端接口,参数是对象,id是唯一标识符
        http.post('/pmt/sysAdminUser/admin/delete?id='+index.id).then(res => {
          this.$message({
            type: res && res.data.code === 666 ?  'success' : 'error',
            message: res.data.responseMsg
          })
          this.getList()
        });
      }).catch(() => {
        // 点击取消:不删除了
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleResetPwd(index) {
      this.$confirm('此操作将重置该用户密码为admin123, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作:根据后端接口,参数是对象,id是唯一标识符
        http.post('/pmt/sysAdminUser/admin/resetPwd?id='+index.id).then(res => {
          this.$message({
            type: res && res.data.code === 666 ?  'success' : 'error',
            message: res.data.responseMsg
          })
          this.getList()
        });
      }).catch(() => {
        // 点击取消:不删除了
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新建按钮
    handleCreate() {
      this.modalType = 0
      this.openForm()
    },
    // 打开表单
    openForm() {
      this.dialogVisible = true
    },
    // 改变页码
    currentChange(val) {
      this.pageData.page = val
      this.getList()
    },
    // 搜索
    search() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.manage {
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .common-table {
    height: 90%;
    position: relative;

    .pager {
      position: absolute;
      right:20px;
      bottom: 0;
    }
  }
}
</style>
import axios from "axios";

// 封装一个axios实例
const http = axios.create({
    // 通用请求的地址前缀
    baseURL: 'https://www.xjy.asia',
    // baseURL: 'http://127.0.0.1:8009',
    // 超时时间
    timeout: 10000,
})

// 请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做什么
    return config;
}, function (error) {
    // 对请求错误做什么
    return Promise.reject(error);
})

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 对响应数据做什么
    if(response.data.code === 1000){
        console.log('跳到登录页')
        sessionStorage.clear();
        this.$router.push('/login')
    }
    return response;
}, function (error) {
    // 对响应错误做什么
    console.log("haha", error)
    return Promise.reject(error);
})

export default http
<template>
  <div>
    <div><el-form ref="form" class="login_container" :model="login" status-icon :rules="rules" label-width="70px">
      <!-- h3要放在里面:只能有一个根,且title也是表单的一部分 -->
      <h3 class="login_title">用户登录</h3>
      <!-- prop对应rules里的键 -->
      <el-form-item label="用户名" prop="username">
        <el-input v-model="login.username" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="login.password" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="submit" type="primary" style="margin-left:30px;margin-top:10px">提交</el-button>
      </el-form-item>
    </el-form></div>
    <div>
      廖家快乐&nbsp;&nbsp;&nbsp;&nbsp;备案号：<a style="text-decoration-color: #333333; text-decoration-line: none;" href="https://beian.miit.gov.cn" target="_blank">渝ICP备2024036682号-2</a>
    </div>
  </div>
</template>

<script>
import http from "@/utils/request";
import JSEncrypt from 'jsencrypt'
export default {
  data() {
    return {
      // 登陆数据
      login: {
        username: '',
        password: ''
      },
      // 校验规则
      rules: {
        username: [{required: 'true', message: '请输入用户名', trigger: 'blur'}],
        password: [{required: 'true', message: '请输入用户名', trigger: 'blur'}]
      }
    }
  },
  methods: {
    submit() {
      // 表单的校验
      this.$refs.form.validate((valid) => {
        if (valid) {
          //验证登录
          var encryptor = new JSEncrypt()
          var pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC8hCXIBR/lYuEZzqao/ToB7BUg0RT5PZII01m4rWIuIImImaBcex9HftwTwHKqS55UNK6MnR1ZAvgLlGf7AQGMiS1nAui+xqA5WKxJFYG2WRtK7GEheHA6/rKLhqikykKuzQKMnCUz4c2UaBpZaPSyYUEJ2o5aI9NSxkT2N/rKUwIDAQAB'
          encryptor.setPublicKey(pubKey)//设置公钥
          var rsaPassWord = encryptor.encrypt(this.login.password)
          http.post('/pmt/login/doLogin', {'userName':this.login.username,'password':rsaPassWord}).then(res =>{
            console.log(res)
            if(res && res.data.code === 666){
              // 记录cookie
              sessionStorage.setItem('isLogin', 'true');
              sessionStorage.setItem('userinfo', JSON.stringify(res.data.tData.userInfo));
              // 设置菜单
              this.$store.commit('setMenu', res.data.tData.menuList)
              // 动态添加路由
              this.$store.commit('addMenu', this.$router)
              // 跳转到首页
              this.$router.push('/home')
            } else {
              // 验证失败的弹窗
              this.$message.error(res.data.responseMsg);
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  width: 350px;
  border: 1px solid #eaeaea;

  // 居中
  margin: 180px auto;

  padding: 35px 35px 15px 35px;

  // 让padding在width里面
  box-sizing: border-box;

  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 0 25px #cac6c6;

  .login_title {
    color: #505458;
    // 左右居中
    text-align: center;
    margin-bottom: 40px;
  }

  .el-input {
    width: 198px;
  }
}
</style>
<template>
  <div class="header-container">
    <div class="l-content">
      <el-button @click="handleMenu" icon="el-icon-menu" size="mini"></el-button>
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in tags" :key="item.path" :to="{ path: item.path }">{{ item.label }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <el-dropdown @command="handleClick">
                <span class="el-dropdown-link">
                    <img class="user" src="../assets/images/user.png" alt="">
                </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="updatePwdDialog">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" :visible.sync="updatePwdDialogVisible" width="350px" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="updatePwdform" class="updatepwd_container" :model="userPwd" status-icon :rules="updatePwdRules"
               label-width="100px">
        <!-- h3要放在里面:只能有一个根,且title也是表单的一部分 -->
        <!-- prop对应rules里的键 -->
        <el-form-item label="旧密码" prop="oldPwd">
          <el-input type="password" v-model="userPwd.oldPwd"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input type="password" v-model="userPwd.newPwd"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPwd2">
          <el-input type="password" v-model="userPwd.newPwd2"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="submit" type="primary" style="margin-left:30px;margin-top:10px">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import http from "@/utils/request";
import JSEncrypt from "jsencrypt";

export default {
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userPwd.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      userPwd: {},
      updatePwdDialogVisible: false,
      // 校验规则
      updatePwdRules: {
        oldPwd: [{required: 'true', min: 6, max: 12, message: '请输入6-12位旧密码', trigger: 'blur'}],
        newPwd: [{required: 'true', min: 6, max: 12, message: '请输入6-12位新密码', trigger: 'blur'}],
        newPwd2: [{
          required: 'true',
          min: 6,
          max: 12,
          message: '请输入6-12位确认密码',
          trigger: 'blur'
        }, {validator: validatePass2, trigger: 'blur'}],
      },
    }
  },
  methods: {

    submit() {
      // 表单的校验
      this.$refs.updatePwdform.validate((valid) => {
        if (valid) {
          var encryptor = new JSEncrypt()
          var pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC8hCXIBR/lYuEZzqao/ToB7BUg0RT5PZII01m4rWIuIImImaBcex9HftwTwHKqS55UNK6MnR1ZAvgLlGf7AQGMiS1nAui+xqA5WKxJFYG2WRtK7GEheHA6/rKLhqikykKuzQKMnCUz4c2UaBpZaPSyYUEJ2o5aI9NSxkT2N/rKUwIDAQAB'
          encryptor.setPublicKey(pubKey)//设置公钥
          const paramObj = {
            newPwd: encryptor.encrypt(this.userPwd.newPwd),
            oldPwd: encryptor.encrypt(this.userPwd.oldPwd),
          }
          http.post('/pmt/sysAdminUser/admin/updateMyPwd', paramObj).then(res => {
            console.log(res)
            this.$message(res.data.responseMsg);
            if (res && res.data.code === 666) {
              this.updatePwdDialogVisible = false
            }
          })
        }
      })
    },
    handleClose() {
      this.updatePwdDialogVisible = false
    },
    handleMenu() {
      // 相当于调用这个方法
      this.$store.commit('CollapseMenu')
    },
    handleClick(command) {
      if (command === 'logout') {
        sessionStorage.clear();
        http.post('/pmt/login/logout', {});
        this.$router.push('/login')
      } else if (command === 'updatePwdDialog') {
        console.log("updatePwdDialog")
        this.updatePwdDialogVisible = true
      }
    }
  },
  computed: {
    ...mapState({
      tags: state => state.tab.tabList
    })
  }
}
</script>

<style lang="less" scoped>
.header-container {
  background-color: #333;
  height: 60px;

  // 让按钮和头像居中
  display: flex;
  justify-content: space-between;
  align-items: center;
  // 不要紧贴边框
  padding: 0 20px;

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;

    .user {
      width: 40px;
      height: 40px;
      // 50%变圆形
      border-radius: 50%;
    }
  }
}

.l-content {
  display: flex;
  // 上下居中
  align-items: center;

  .el-breadcrumb {
    margin-left: 15px;

    // deep 强制生效
    /deep/ .el-breadcrumb__item {
      .el-breadcrumb__inner {
        &.is-link {
          color: #666;
        }
      }

      &:last-child {
        .el-breadcrumb__inner {
          color: #fff;
        }
      }
    }
  }
}
</style>
<template>
  <div class="manage">
    <el-dialog title="选择不通过原因" :visible.sync="approveSubmitNotVisible">
      <el-form :model="notForm" ref="notForm">
        <el-form-item label="活动区域" label-width="400" :before-close="notFormCloseDialog">
          <el-select v-model="notForm.opinion" placeholder="请选不通过原因">
            <el-option
                v-for="item in opinionSelectList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="approveSubmitNot">确 定</el-button>
      </div>
    </el-dialog>
    <div class="manage-header">
      <!-- 对话框:点击新增或编辑才会弹出表单 -->
      <!-- :before-close="closeDialog" 点击关闭的x之前要做的事情 -->
      <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :before-close="closeDialog">
        <el-table :data="applicationFormImages" stripe style="width: 100%" height="70%">
          <el-table-column prop="id" label="id" width="50"></el-table-column>
          <el-table-column prop="" label="图片">
            <template v-slot="scope">
              <el-image
                  style="width: 100px; height: 100px"
                  :src='scope.row.fileUrlPath'
                  :preview-src-list='[scope.row.fileUrlPath]'
                  :fit="fit"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="auditStatusStr" label="状态" width="100"></el-table-column>
          <el-table-column prop="opinionStr" label="审核意见"></el-table-column>
          <el-table-column label="操作" width="200">
            <template v-slot="scope">
              <el-button type="text" @click="approveSubmit(scope.row, 2)">通过</el-button>
              <el-button type="text" @click="shopNotDialog(scope.row)">不通过</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-button type="primary"  size="small" @click="approveSubmitBySelect(applicationFormImages)">全部通过</el-button>
        </div>
        <div style="height: 40px; margin-inside: 5px;">
          <div style="float: left; width: 70%;  ">提交件数:{{editNumber.formNumber}}, 实际数量:&nbsp;<el-input-number size="small" v-model="editNumber.auditFormNumber" :step="1" step-strictly></el-input-number></div>
          <div style="float: left; width: 30%;  "><el-button size="small" type="primary" @click="submitNumber()">确定</el-button></div>
        </div>
        <div  style="height: 40px; margin-inside: 5px;">
          <div style="float: left; width: 70%;  ">审核意见:&nbsp;<el-input size="small" v-model="editAuditOpinion.auditOpinion" style="width: 80%;"></el-input></div>
          <div style="float: left; width: 30%;  "><el-button size="small" type="primary" @click="submitAuditOpinion()">确定</el-button></div>
        </div>
      </el-dialog>

      <!-- 搜索框 -->
      <el-form :inline="true">
        <el-form-item>
          <el-select v-model="searchForm.shopInfoId" clearable placeholder="请选择商铺">
            <el-option
                v-for="item in shopSelectList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker type="datetime" v-model="searchForm.startTime" value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="开始时间"/>
          <el-date-picker type="datetime" v-model="searchForm.endTime" value-format="yyyy-MM-dd HH:mm:ss"
                          default-time="23:59:59" placeholder="结束时间"/>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.formStatus" clearable  placeholder="状态">
            <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <!-- 用户数据Table -->
      <el-table :data="tableData" stripe style="width: 100%" height="90%">
        <el-table-column prop="id" label="id" width="50"></el-table-column>
        <el-table-column prop="taskName" label="任务名称"></el-table-column>
        <el-table-column prop="userName" label="促销员"></el-table-column>
        <el-table-column prop="shopInfoName" label="所属商铺"></el-table-column>
        <el-table-column prop="createTime" label="提交时间"></el-table-column>
        <el-table-column prop="formNumber" label="提交件数" width="100"></el-table-column>
        <el-table-column prop="auditFormNumber" label="实际数量" width="100"></el-table-column>
        <el-table-column prop="amount" label="实际金额" width="100"></el-table-column>
        <el-table-column prop="estimatedAmount" label="预计金额" width="100"></el-table-column>
        <el-table-column prop="formStatusStr" label="状态" width="100"></el-table-column>
        <!-- 自定义列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="approveForm(scope.row)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pager">
        <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request'

export default {
  data() {
    return {
      // 不通过原因
      notForm: {
        opinion: '',
        id: '',
        auditStatus: 1,
      },
      // 表单验证规则
      rules: {
        realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
        wxNickname: [{required: true, message: '请输入微信姓名', trigger: 'blur'}],
        phone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
        shopInfoId: [{required: true, message: '请选择商铺', trigger: 'blur'}],
        userType: [{required: true, message: '请选择类别', trigger: 'blur'}],
        expirationTime: [{required: true, message: '请选择到期时间', trigger: 'blur'}],
      },
      // 相看框是否打开
      dialogVisible: false,
      //不通过原因是否打开
      approveSubmitNotVisible: false,
      // 列表数据
      tableData: [],
      // 分页的对象
      pageData: {
        page: 1,
        limit: 20
      },
      // 分页页数
      total: 0,
      // 搜索框表单
      searchForm: {
        shopInfoId: '',
        startTime: '',
        endTime: '',
        formStatus: '',
      },
      //单据对应的图片列表
      applicationFormImages: [],
      //商铺下拉框数据
      shopSelectList: [],
      //审核意见
      opinionSelectList: [],
      //状态
      statusList:[],
      //修改数量
      editNumber:{
        auditFormNumber:0,
        formNumber:0,
        id:0,
      },
      //修改审核意见
      editAuditOpinion:{
        auditOpinion:'',
        id:0,
      }
    }
  },
  created() {
    //加载页面初始信息
    this.getBaseData()
  },
  methods: {
    // 获取页面初始化信息
    getBaseData() {
      http.get('/pmt/applicationForm/admin/getBasicData')
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.shopSelectList = tData.shopSelectList
              this.opinionSelectList = tData.opinionSelectList
              this.statusList = tData.statusList
            } else {
              this.$message(res.data.responseMsg);
            }
          })

    },
    //修改数量
    submitNumber(){
      http.post('/pmt/applicationForm/admin/updateNumber', this.editNumber)
          .then(res => {
            if (res.data.code === 666) {
              this.$message(res.data.responseMsg);
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    //输入审核意见
    submitAuditOpinion(){
      http.post('/pmt/applicationForm/admin/updateAuditOpinion', this.editAuditOpinion)
          .then(res => {
            if (res.data.code === 666) {
              this.$message(res.data.responseMsg);
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 获取列表数据
    getList() {
      http.post('/pmt/applicationForm/admin/getApplicationFormPageList', this.searchForm)
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.pageData.page = tData.pageNum
              this.pageData.limit = tData.pageSize
              this.total = tData.total
              this.tableData = tData.list
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    //关闭不通过框
    notFormCloseDialog() {
      this.approveSubmitNotVisible = false
    },
    // 关闭对话框
    closeDialog() {
      this.applicationFormImages = []
      // 后关闭
      this.dialogVisible = false
      //刷新列表数据
      this.getList()
    },
    // 审核按钮
    approveForm(index) {
      http.get('/pmt/applicationForm/admin/getFormImagesByFormId?formId=' + index.id)
          .then(res => {
            if (res.data.code === 666) {
              this.applicationFormImages = res.data.tData
              this.dialogVisible = true
              this.editNumber.id = index.id
              this.editNumber.formNumber = index.formNumber
              this.editNumber.auditFormNumber = index.auditFormNumber
              this.editAuditOpinion.id = index.id
              this.editAuditOpinion.auditOpinion = index.auditOpinion
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    //单个提交审核
    approveSubmit(index, status, message) {
      http.post('/pmt/applicationForm/admin/updateImagesStatus', [{
        "id": index.id,
        "opinion": message,
        "auditStatus": status
      }])
          .then(res => {
            if (res.data.code === 666) {
              this.applicationFormImages = res.data.tData
              this.dialogVisible = true
            } else {
              this.$message(res.data.responseMsg);
            }
          })

    },
    //打开不通过弹窗
    shopNotDialog(index) {
      this.notForm.id = index.id
      this.approveSubmitNotVisible = true
    },
    //提交不通过审核
    approveSubmitNot() {
      this.notForm.auditStatus = 1
      http.post('/pmt/applicationForm/admin/updateImagesStatus', [this.notForm])
          .then(res => {
            if (res.data.code === 666) {
              this.applicationFormImages = res.data.tData
              this.approveSubmitNotVisible = false
              this.notForm.opinion = ''
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 审核按钮--提交已经选择的
    approveSubmitBySelect(index) {
      console.log(index)
      const applicationFormId = index[0].applicationFormId;
      http.post('/pmt/applicationForm/admin/updateImagesStatusWithFormId?applicationFormId=' + applicationFormId)
          .then(res => {
            if (res.data.code === 666) {
              this.applicationFormImages = res.data.tData
              this.approveSubmitNotVisible = false
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 改变页码
    currentChange(val) {
      this.pageData.page = val
      this.getList()
    },
    // 搜索
    search() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.manage {
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .common-table {
    height: 90%;
    position: relative;

    .pager {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
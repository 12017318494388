<template>
  <div class="manage">
    <div class="manage-header">
      <!-- 新增按钮 -->
      <el-button type="primary" @click="handleCreate">+ 新增</el-button>
      <!-- 对话框:点击新增或编辑才会弹出表单 -->
      <!-- :before-close="closeDialog" 点击关闭的x之前要做的事情 -->
      <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :before-close="closeDialog"
                 :close-on-click-modal="false">
        <!-- 表单Form -->
        <!-- ref=form:为了通过this.$refs调用组件的方法 -->
        <el-form :inline="true" :model="form" :rules="rules" ref="form" label-width="80px">
          <!-- 每一项表单域:el-form-item -->
          <el-form-item label="商铺名称" prop="shopName">
            <el-input placeholder="请输入名称" v-model="form.shopName"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="shopAddress">
            <el-input placeholder="请输入地址" v-model="form.shopAddress"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="photo">
            <el-input placeholder="请输入手机号" v-model="form.photo"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="bossName">
            <el-input placeholder="请输入联系人" v-model="form.bossName"></el-input>
          </el-form-item>
          <el-form-item label="级别" prop="level">
            <el-col :span="12">
              <el-select v-model="form.level" placeholder="请选择级别">
                <el-option
                    v-for="item in shopLevelList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="销售人员" prop="adminUserId">
            <el-col :span="12">
              <el-select v-model="form.adminUserId" placeholder="请选择销售">
                <el-option
                    v-for="item in adminUserList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" placeholder="请输入联系人" v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="库存" :visible.sync="kcDialogVisible" width="80%" :before-close="closeKcDialog"
                 :close-on-click-modal="false">
        <el-table :data="kcListData" style="width: 100%;" :max-height="kcTableHeight">
          <el-table-column prop="goodsName" label="商品名称" width="280"></el-table-column>
          <el-table-column prop="goodsImgUrl" label="图片" width="100">
            <template v-slot="scope">
              <el-image :src="scope.row.goodsImgUrl" class="avatar"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="specification" label="规格" width="200"></el-table-column>
          <el-table-column prop="totalQuantity" label="原始库存"></el-table-column>
          <el-table-column prop="newTotalQuantity" label="调整库存">
            <template v-slot="scope">
              <el-input-number v-model="scope.row.newTotalQuantity" placeholder="请输入调整库存" size="small"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button type="text" @click="handleKcEdit(scope.row)">修改库存</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>


      <!-- 搜索框 -->
      <el-form :inline="true">
        <el-form-item>
          <el-input v-model="searchForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.adminUserId" clearable placeholder="请选择销售" filterable>
            <el-option
                v-for="item in adminUserList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <!-- 商铺Table -->
      <el-table :data="tableData" border style="width: 100%" height="90%">
        <el-table-column width="50" prop="id" label="id" fixed></el-table-column>
        <el-table-column prop="shopName" label="名称" fixed></el-table-column>
        <el-table-column prop="shopAddress" label="地址"></el-table-column>
        <el-table-column prop="bossName" label="联系人"></el-table-column>
        <el-table-column prop="photo" label="电话"></el-table-column>
        <el-table-column prop="levelStr" label="级别"></el-table-column>
        <el-table-column prop="adminUserIdStr" label="销售人员"></el-table-column>
        <el-table-column prop="relatedSysUsers" label="促销人员"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <!-- 自定义列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
            <el-button type="text" @click="handleKc(scope.row)">修改库存</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pager">
        <el-pagination layout="prev, pager, next" :total="total" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request'

export default {
  data() {
    return {
      // 表单绑定的数据
      form: {
        shopName: '',
        shopAddress: '',
        bossName: '',
        photo: '',
        level: '',
        remark: '',
        adminUserId: '',
      },
      // 表单验证规则
      rules: {
        shopName: [{required: true, message: '请输入商铺名称', trigger: 'blur'}],
        shopAddress: [{required: true, message: '请输入地址', trigger: 'blur'}],
        bossName: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        photo: [{required: true, message: '请输入电话', pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, trigger: 'blur'}],
        level: [{required: true, message: '请选择级别', trigger: 'blur'}],
        adminUserId: [{required: true, message: '请选择销售员', trigger: 'blur'}],
      },
      // 表单是否打开
      dialogVisible: false,
      //库存弹窗是否打开
      kcDialogVisible: false,
      // 列表数据
      tableData: [],
      // 打开表单:新建0,编辑1
      modalType: 0,
      // 分页的对象
      pageData: {
        page: 1,
        limit: 20
      },
      // 分页页数
      total: 0,
      // 搜索框表单
      searchForm: {
        keyWords: '',
        adminUserId: '',
      },
      //商铺下拉
      shopLevelList: [],
      //销售下拉
      adminUserList: [],
      //库存
      kcListData: [],
      kcTableHeight: 500,
    }
  },
  created() {
    //加载页面初始信息
    this.getBaseData()
  },
  methods: {
    //获取页面初始化信息
    getBaseData() {
      http.get('/pmt/shopInfo/admin/getBasicData')
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.shopLevelList = tData.shopLevelList
              this.adminUserList = tData.adminUserList
            } else {
              this.$message(res.data.responseMsg);
            }
          })

    },

    // 获取列表数据
    getList() {
      http.post('/pmt/shopInfo/admin/getShopPageList', this.searchForm)
          .then(res => {
            if (res.data.code === 666) {
              const tData = res.data.tData
              this.pageData.page = tData.pageNum
              this.pageData.limit = tData.pageSize
              this.total = tData.total
              this.tableData = tData.list
            } else {
              this.$message(res.data.responseMsg);
            }
          })
    },
    // 表单提交
    submit() {
      // 要用箭头函数,若用function会报错,不知道为什么
      this.$refs.form.validate((valid) => {
        // 符合校验
        if (valid) {
          // 编辑
          http.post('/pmt/shopInfo/admin/save', this.form).then(() => {
            this.getList()
          })
          // 清空,关闭
          this.closeDialog()
        }
      })
    },
    // 关闭对话框
    closeDialog() {
      // 先重置
      this.$refs.form.resetFields()
      // 后关闭
      this.dialogVisible = false
    },
    // 关闭库存弹窗
    closeKcDialog() {
      // 后关闭
      this.kcDialogVisible = false
    },
    // 编辑按钮
    handleEdit(index) {
      this.modalType = 1
      this.openForm()
      // 深拷贝
      this.form = JSON.parse(JSON.stringify(index))
    },
    // 库存
    handleKc(index) {
      this.kcListData = []
      this.kcDialogVisible = true
      // 查询库存
      http.get('/pmt/shopInventory/admin/getAllInventoryByShopId?shopId=' + index.id).then(res => {
        if (res.data.code === 666) {
          this.kcListData = res.data.tData
        } else {
          this.$message(res.data.responseMsg)
        }
      });
    },
    //修改库存
    handleKcEdit(index) {
      const param = {
        'totalQuantity': index.newTotalQuantity,
        'shopInfoId': index.shopInfoId,
        'goodsId': index.id,
      }
      http.post('/pmt/shopInventory/admin/saveInventory', param).then(res => {
        if (res.data.code === 666) {
          index.totalQuantity = index.newTotalQuantity;
        }
        this.$message({
          type: res && res.data.code === 666 ? 'success' : 'error',
          message: res.data.responseMsg
        })
      });
    },
    // 删除按钮
    handleDelete(index) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作:根据后端接口,参数是对象,id是唯一标识符
        http.post('/pmt/shopInfo/admin/delete?id=' + index.id).then(res => {
          this.$message({
            type: res && res.data.code === 666 ? 'success' : 'error',
            message: res.data.responseMsg
          })
          this.getList()
        });
      }).catch(() => {
        // 点击取消:不删除了
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新建按钮
    handleCreate() {
      this.modalType = 0
      this.openForm()
    },
    // 打开表单
    openForm() {
      this.dialogVisible = true
    },
    // 改变页码
    currentChange(val) {
      this.pageData.page = val
      this.getList()
    },
    // 搜索
    search() {
      this.getList()
    }
  },
  mounted() {
    this.getList();
    this.$nextTick(() => {
      this.kcTableHeight = window.innerHeight - 280;
    });
  }
}
</script>

<style lang="less" scoped>
.manage {
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .common-table {
    height: 90%;
    position: relative;

    .pager {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>